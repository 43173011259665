import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Draggable);


document.addEventListener('DOMContentLoaded', function () {
  new Splide('#sliderSmall1', {
    type: 'loop',
    arrows: false,
    perPage: 1,
    drag: true,
    // autoplay: true,
    // interval: 3000,
  }).mount();
});

document.addEventListener('DOMContentLoaded', function () {
  new Splide('#sliderSmall2', {
    type: 'loop',
    arrows: false,
    perPage: 1,
    drag: true,
    // autoplay: true,
    // interval: 3000,
  }).mount();
});

document.addEventListener('DOMContentLoaded', function () {
  new Splide('#sliderSmall3', {
    type: 'loop',
    arrows: false,
    perPage: 1,
    drag: true,
    // autoplay: true,
    // interval: 3000,
  }).mount();
});

document.addEventListener('DOMContentLoaded', function () {
  new Splide('#sliderSmall4', {
    type: 'loop',
    arrows: false,
    perPage: 1,
    drag: true,
    // autoplay: true,
    // interval: 3000,
  }).mount();
});

document.addEventListener('DOMContentLoaded', function () {
  new Splide('#sliderSmall5', {
    type: 'loop',
    arrows: false,
    perPage: 1,
    drag: true,
    // autoplay: true,
    // interval: 3000,
  }).mount();
});


//news Drag&drop with slider
document.addEventListener('DOMContentLoaded', function () {
  const container = document.getElementById('dragNDropSlider');
  const draggableItems = document.querySelectorAll('.newsSlider__dragNDropIcon__item');

  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;

  draggableItems.forEach((item, index) => {
    const originalWidth = item.clientWidth;
    const originalHeight = item.clientHeight;

    // Генерация случайного размера: минимум 150px, максимум — оригинальный размер
    const minSize = 150;
    const randomWidth = Math.random(1) * (originalWidth - minSize) + minSize;
    const randomHeight = (randomWidth / originalWidth) * originalHeight;

    // Генерация случайных координат с учётом нового размера
    const randomX = Math.random() * (containerWidth - randomWidth);
    const randomY = Math.random() * (containerHeight - randomHeight);

    // Генерация случайного поворота от -15 до 15 градусов
    const randomRotation = Math.random() * 30 - 15;

    // Устанавливаем начальные параметры: позиции, размер и поворот
    gsap.set(item, {
      x: randomX,
      y: randomY,
      width: randomWidth,
      height: randomHeight,
      rotation: randomRotation,  
      zIndex: Math.floor(Math.random() * 100) + 1 
    });

    // Создаем Draggable для каждого элемента
    Draggable.create(item, {
      type: 'x,y',
      bounds: container,         // Ограничиваем перемещение областью контейнера
      inertia: true,             // Добавляем инерцию
      zIndexBoost: true,         // Перемещаем элемент на передний план при перетаскивании
      onDragEnd: function () {
        console.log('Dragging ended for', item.id);
      },
    });
  });
});

document.addEventListener('DOMContentLoaded', function () {
  const container = document.getElementById('dragNDropSlider2');
  const draggableItems = document.querySelectorAll('.dragNDropSlider2__item');

  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;

  draggableItems.forEach((item, index) => {
    const originalWidth = item.clientWidth;
    const originalHeight = item.clientHeight;

    // Генерация случайного размера: минимум 150px, максимум — оригинальный размер
    const minSize = 150;
    const randomWidth = Math.random(1) * (originalWidth - minSize) + minSize;
    const randomHeight = (randomWidth / originalWidth) * originalHeight;

    // Генерация случайных координат с учётом нового размера
    const randomX = Math.random() * (containerWidth - randomWidth);
    const randomY = Math.random() * (containerHeight - randomHeight);

    // Генерация случайного поворота от -15 до 15 градусов
    const randomRotation = Math.random() * 30 - 15;

    // Устанавливаем начальные параметры: позиции, размер и поворот
    gsap.set(item, {
      x: randomX,
      y: randomY,
      width: randomWidth,
      height: randomHeight,
      rotation: randomRotation,  
      zIndex: Math.floor(Math.random() * 100) + 1 
    });

    // Создаем Draggable для каждого элемента
    Draggable.create(item, {
      type: 'x,y',
      bounds: container,         // Ограничиваем перемещение областью контейнера
      inertia: true,             // Добавляем инерцию
      zIndexBoost: true,         // Перемещаем элемент на передний план при перетаскивании
      onDragEnd: function () {
        console.log('Dragging ended for', item.id);
      },
    });
  });
});

document.addEventListener('DOMContentLoaded', function () {
  const container = document.getElementById('dragNDropSlider4');
  const draggableItems = document.querySelectorAll('.dragNDropSlider4__item');

  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;

  draggableItems.forEach((item, index) => {
    const originalWidth = item.clientWidth;
    const originalHeight = item.clientHeight;

    // Генерация случайного размера: минимум 150px, максимум — оригинальный размер
    const minSize = 150;
    const randomWidth = Math.random(1) * (originalWidth - minSize) + minSize;
    const randomHeight = (randomWidth / originalWidth) * originalHeight;

    // Генерация случайных координат с учётом нового размера
    const randomX = Math.random() * (containerWidth - randomWidth);
    const randomY = Math.random() * (containerHeight - randomHeight);

    // Генерация случайного поворота от -15 до 15 градусов
    const randomRotation = Math.random() * 30 - 15;

    // Устанавливаем начальные параметры: позиции, размер и поворот
    gsap.set(item, {
      x: randomX,
      y: randomY,
      width: randomWidth,
      height: randomHeight,
      rotation: randomRotation,  
      zIndex: Math.floor(Math.random() * 100) + 1 
    });

    // Создаем Draggable для каждого элемента
    Draggable.create(item, {
      type: 'x,y',
      bounds: container,         // Ограничиваем перемещение областью контейнера
      inertia: true,             // Добавляем инерцию
      zIndexBoost: true,         // Перемещаем элемент на передний план при перетаскивании
      onDragEnd: function () {
        console.log('Dragging ended for', item.id);
      },
    });
  });
});

//walkman
// document.addEventListener('DOMContentLoaded', function () {
//   const container = document.getElementById('dragNDropSliderGrey');
//   const draggableItems = document.querySelectorAll('.newsSliderGrey__item');

//   // const containerWidth = container.clientWidth;
//   // const containerHeight = container.clientHeight;

//   draggableItems.forEach((item, index) => {
//     const originalWidth = item.clientWidth;
//     const originalHeight = item.clientHeight;

//     // Генерация случайного размера: минимум 150px, максимум — оригинальный размер
//     const minSize = 150;
//     const randomWidth = Math.random() * (originalWidth - minSize) + minSize;
//     const randomHeight = (randomWidth / originalWidth) * originalHeight;

//     // Генерация случайных координат с учётом нового размера
//     const randomX = Math.random() * (containerWidth - randomWidth);
//     const randomY = Math.random() * (containerHeight - randomHeight);

//     // Генерация случайного поворота от -15 до 15 градусов
//     const randomRotation = Math.random() * 30 - 15;

//     // Устанавливаем начальные параметры: позиции, размер и поворот
//     gsap.set(item, {
//       x: randomX,
//       y: randomY,
//       width: randomWidth,
//       height: randomHeight,
//       rotation: randomRotation,  // Применяем случайный поворот
//       zIndex: Math.floor(Math.random() * 100) + 1            // Устанавливаем z-index для контроля порядка
//     });

//     // Создаем Draggable для каждого элемента
//     Draggable.create(item, {
//       type: 'x,y',
//       bounds: container,         // Ограничиваем перемещение областью контейнера
//       inertia: true,             // Добавляем инерцию
//       zIndexBoost: true,         // Перемещаем элемент на передний план при перетаскивании
//       onDragEnd: function () {
//         console.log('Dragging ended for', item.id);
//       },
//     });
//   });
// });


//news Drag&drop white
document.addEventListener('DOMContentLoaded', function () {
  const container = document.getElementById('interactiveNewsContainer');
  const draggableItems = document.querySelectorAll('.newsBig__item');

  const containerWidth = container.clientWidth;
  const containerHeight = container.clientHeight;

  draggableItems.forEach((item, index) => {
    const originalRect = item.getBoundingClientRect();
    const originalWidth = originalRect.width;
    const originalHeight = originalRect.height;

    const minSize = 150;
    const randomWidth = Math.random() * (originalWidth - minSize) + minSize;
    const randomHeight = (randomWidth / originalWidth) * originalHeight;
    const randomX = Math.random() * (containerWidth - randomWidth);
    const randomY = Math.random() * (containerHeight - randomHeight);
    const randomRotation = Math.random() * 30 - 15;

    gsap.set(item, {
      x: randomX,
      y: randomY,
      width: randomWidth,
      height: randomHeight,
      rotation: randomRotation,  
      zIndex: Math.floor(Math.random() * 100) + 1 
    });

    Draggable.create(item, {
      type: 'x,y',
      bounds: container,
      inertia: true,
      zIndexBoost: true,
      allowContextMenu: false,
      onDragEnd: function () {
        console.log('Dragging ended for', item.id);
      },
    });
  });
});


//newsImages
document.addEventListener('DOMContentLoaded', function () {
  const images = document.querySelectorAll('.ddItem');
  const galleryWidth = document.querySelector('#stackGallery').offsetWidth;
  const galleryHeight = document.querySelector('#stackGallery').offsetHeight;

  // Ограничение максимальной ширины
  const maxWidth = galleryWidth * 0.3;

  // Установка минимальной ширины в зависимости от устройства
  const minWidth = window.innerWidth < 768 ? galleryWidth * 0.30 : galleryWidth * 0.13;

  images.forEach((image) => {
    const originalWidth = image.clientWidth;
    const originalHeight = image.clientHeight;

    // Генерация случайного размера между minWidth и maxWidth
    let randomWidth = Math.random() * (maxWidth - minWidth) + minWidth;
    let randomHeight = (randomWidth / originalWidth) * originalHeight;

    // Генерация случайных координат с учётом нового размера
    const randomX = Math.random() * (galleryWidth - randomWidth);
    const randomY = Math.random() * (galleryHeight - randomHeight);

    // Сохраняем координаты в data-атрибуты, чтобы использовать их позже
    image.dataset.x = randomX;
    image.dataset.y = randomY;

    // Устанавливаем начальные позиции, размеры и z-index
    gsap.set(image, {
      x: randomX,
      y: randomY,
      width: randomWidth,       // Применяем случайную ширину с ограничением
      height: randomHeight,     // Применяем соответствующую высоту с ограничением
      zIndex: Math.floor(Math.random() * 100) + 1  // Устанавливаем z-index для каждого элемента
    });

    // Инициализация Draggable с учётом новых размеров
    Draggable.create(image, {
      bounds: "#stackGallery", // Ограничиваем перетаскивание границами галереи
      zIndexBoost: true,       // Выдвигаем элемент на передний план при перетаскивании
      inertia: true,           // Добавляем инерцию
      onPressInit: function () {
        // Перед началом перетаскивания сохраняем текущие координаты
        this.startX = parseFloat(image.dataset.x);
        this.startY = parseFloat(image.dataset.y);
      },
      onDrag: function () {
        // Обновляем координаты при перетаскивании
        image.dataset.x = this.x;
        image.dataset.y = this.y;
      }
    });
  });

  // Слушаем клик на картинке, чтобы выдвинуть её на передний план
  images.forEach((image) => {
    image.addEventListener('click', function () {
      images.forEach((img) => (img.style.zIndex = 1)); // Сбрасываем z-index у всех картинок
      this.style.zIndex = 10; // Выдвигаем выбранную картинку на передний план
    });
  });
});

//sound control
const video = document.getElementById("customVideo");
const playPauseBtn = document.getElementById("playPauseBtn");
const playIcon = document.getElementById("playIcon");
const pauseIcon = document.getElementById("pauseIcon");
const muteBtn = document.getElementById("muteBtn");
const volumeIcon = document.getElementById("volumeIcon");
const muteIcon = document.getElementById("muteIcon");
const volumeSlider = document.getElementById("volumeSlider");

// Функция проигрывания/паузы
playPauseBtn.addEventListener("click", () => {
  if (video.paused) {
    video.play();
    playIcon.style.display = "none";
    pauseIcon.style.display = "block";
  } else {
    video.pause();
    playIcon.style.display = "block";
    pauseIcon.style.display = "none";
  }
});

// Функция включения/выключения звука
muteBtn.addEventListener("click", () => {
  video.muted = !video.muted;
  if (video.muted) {
    volumeIcon.style.display = "none";
    muteIcon.style.display = "block";
  } else {
    volumeIcon.style.display = "block";
    muteIcon.style.display = "none";
  }
});

// Обновление громкости с помощью ползунка
// volumeSlider.addEventListener("input", (event) => {
//   video.volume = event.target.value;
//   video.muted = event.target.value == 0;
//   volumeIcon.style.display = video.muted ? "none" : "block";
//   muteIcon.style.display = video.muted ? "block" : "none";
// });